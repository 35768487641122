@tailwind base;
@tailwind components;
@tailwind utilities;


/* look into this more */
body {
  margin: 0;
  background-color: black;
  font-family: "JetBrains Mono", monospace;
  font-optical-sizing: auto;
  color: white;
  font-weight: weight;
  font-style: normal;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{
  font-size: 48px;
  font-weight: 600;
}
h2{
  font-size: 36px;
  font-weight: 600;
}
h3{
  font-size: 24px;
  font-weight: 600;
}
code {
  font-family: "JetBrains Mono", monospace;
}
